import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './index.css';
import dishpensary_logo_large from './dishpensary_logo_large.png';
import cm_software_logo from './cm_software_logo.png';
import publicbacklog from './publicbacklog.png';

function App() {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [loggedVisit, setLoggedVisit] = useState(false);

  useEffect(() => {
    console.log('attempting to log visit');
    if (!loggedVisit) {
      fetch('https://api.cmsoftware.biz/landing/log-visit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then((response) => setLoggedVisit(true));
    }
  }, [loggedVisit]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    message: Yup.string().required('Message is required'),
  });

  const submitForm = async (formData) => {
    try {
      const response = await fetch('https://api.cmsoftware.biz/landing/submit-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const result = await response.json();
      console.log('Form submission result:', result);
      // Reset form data or show success message
      formik.resetForm();

      // Update submission status
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error submitting form:', error.message);
      // Show error message
      // Update submission status
      setSubmissionStatus('error');
    }
  };

  const formik = useFormik({
    initialValues: { name: '', email: '', message: '' },
    validationSchema,
    onSubmit: (values) => {
      console.log('Form data:', values);
      // Your form submission logic here
      submitForm(values);
    },
  });

  const getSiteSpecificInformation = (local) => {
    const dark = 'bg-almost-black';
    const light = 'bg-almost-white';
    if (local?.includes('dishpensary') || window.location.href.includes('dishpensary')) {
      return {
        title: 'Dishpensary - Get the dish on your local dispensary',
        background: dark,
        logo: dishpensary_logo_large
      };
    }

    if (local?.includes('rawmilk') || window.location.href.includes('rawmilk')) {
      return {
        title: 'Raw Milk',
        background: light,
        logo: cm_software_logo
      };
    }

    if (local?.includes('gameswithchat') || window.location.href.includes('gameswithchat')) {
      return {
        title: 'Games with Chat',
        background: dark,
        logo: cm_software_logo
      };
    }

    if (local?.includes('productfeedback') || window.location.href.includes('productfeedback')) {
      return {
        title: 'Product Feedback',
        background: light,
        logo: cm_software_logo
      };
    }

    if (local?.includes('scrapely') || window.location.href.includes('scrapely')) {
      return {
        title: 'Scrapely',
        background: light,
        logo: cm_software_logo
      };
    }

    if (local?.includes('publicbacklog') || window.location.href.includes('publicbacklog')) {
      return {
        title: 'Public Backlog',
        background: light,
        logo: publicbacklog
      };
    }

    return {
      title: "CM Software",
      background: light,
      logo: cm_software_logo
    };
  }

  const siteSpecificinformation = getSiteSpecificInformation();
  console.log(siteSpecificinformation);
  document.title = siteSpecificinformation.title;

  return (
    <div className={`min-h-screen flex flex-col items-center justify-center ${siteSpecificinformation.background}`}>
      <div>
        <img src={siteSpecificinformation?.logo} alt="Logo" style={{ maxWidth: '400px' }} />
      </div>
      <div
        className="bg-white p-8 rounded-lg shadow-md text-center w-96 h-auto relative mt-1"
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
        {submissionStatus === 'success' && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mt-4" role="alert">
            <p className="font-bold">Success</p>
            <p>Your message has been submitted successfully!</p>
          </div>
        )}
        {submissionStatus === 'error' && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-4" role="alert">
            <p className="font-bold">Error</p>
            <p>There was an error submitting your message. Please try again.</p>
          </div>
        )}

        <>Want to be notified when the app goes live?</>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm mb-2">
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className={`w-full px-3 py-2 border ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-300'
                } rounded-md`}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <p className="text-red-500 text-xs mt-1">{formik.errors.name}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm mb-2">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className={`w-full px-3 py-2 border ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'
                } rounded-md`}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <p className="text-red-500 text-xs mt-1">{formik.errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm mb-2">
              Message:
            </label>
            <textarea
              id="message"
              name="message"
              rows="3"
              className={`w-full px-3 py-2 border ${formik.touched.message && formik.errors.message ? 'border-red-500' : 'border-gray-300'
                } rounded-md`}
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            {formik.touched.message && formik.errors.message && (
              <p className="text-red-500 text-xs mt-1">{formik.errors.message}</p>
            )}
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default App;
